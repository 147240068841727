import React from "react"
import styled from "styled-components/macro"
import { useInView } from "react-intersection-observer"
import { AspectRatio } from "core/layout"

const Wrapper = styled.div`
  position: relative;

  /* FIXME: MediaBlock > MarginBox konflikts */
  > div {
    padding: 0 !important;
  }
`

const Frame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default function IFrame({ title, src }) {
  const [ref, inView] = useInView({
    rootMargin: "200px 0px",
  })

  return (
    <Wrapper ref={ref}>
      <AspectRatio ratio={9 / 16}>
        {inView && (
          <Frame
            title={title}
            width="560"
            height="315"
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></Frame>
        )}
      </AspectRatio>
    </Wrapper>
  )
}
