import React from "react"
import { motion } from "framer-motion"

const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }

const variants = {
  initial: { y: -100, scale: 1.1, opacity: 0 },
  enter: { y: 0, scale: 1, opacity: 1, transition },
  exit: {
    y: 100,
    scale: 0.9,
    opacity: 0,
    transition: { ...transition, duration: 0.25 },
  },
}

export default function PageTransition({ children }) {
  return (
    <motion.div
      key="transition"
      initial="initial"
      animate="enter"
      exit="exit"
      variants={variants}
      style={{ overflowX: "hidden" }}
    >
      {children}
    </motion.div>
  )
}
