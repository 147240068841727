import React from "react"
import styled, { css } from "styled-components/macro"
import { rem } from "polished"
import { motion, useSpring } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { color, type } from "core/theme"
import { VideoOverlay } from "elements"
import { getFullUrl } from "core/utils"

const Wrapper = styled.div`
  cursor: pointer;

  ${({ disablePause }) =>
    disablePause &&
    css`
      cursor: auto;
    `};
`

const Background = styled.div`
  background-color: ${color.background.ultralight};
  height: 100%;
  position: relative;
`

const Video = styled(motion.video)`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: ${({ objectPosition }) => objectPosition || "50% 50%"};
`

const Gif = styled(motion.div)`
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${({ objectPosition }) => objectPosition || "50% 50%"};
  }
`

const Caption = styled.span`
  ${type.caption};
  display: inline-block;
  margin-top: ${rem(10)};
`

export default function SmartVideo({
  video,
  poster,
  caption,
  disablePause,
  style,
}) {
  const [userPaused, setUserPaused] = React.useState(false)

  const [ref, inView, entry] = useInView({
    rootMargin: "200px 0px",
  })

  const opacity = useSpring(0, { damping: 50 })

  React.useEffect(() => {
    const target = entry ? entry.target : null
    if (target) {
      try {
        if (inView && !userPaused) {
          target.play()
        } else {
          target.pause()
        }
      } catch (error) {}
    }
  }, [inView, entry, userPaused])

  // const { source, pointOfInterest } = video

  const autoPlayAttr = inView ? { autoPlay: true } : {}
  const posterAttr = poster ? { poster: poster } : {}
  // console.log('poster',posterAttr)

  const isGif = /.gif$/.test(video.url)

  return (
    <Wrapper disablePause={disablePause} style={style}>
      <Background>
        {isGif ? (
          <Gif>
            <img src={getFullUrl(video.url)} alt="" />
          </Gif>
        ) : (
          <Video
            ref={ref}
            {...autoPlayAttr}
            {...posterAttr}
            // objectPosition={pointOfInterest}
            loop
            muted
            playsInline
            preload="metadata"
            style={{ opacity }}
            onLoadedData={() => {
              opacity.set(1)
            }}
            onClick={({ target }) => {
              if (!disablePause) {
                try {
                  if (target.paused) {
                    target.play()
                  } else {
                    target.pause()
                  }
                } catch (error) {}

                setUserPaused(target.paused)
              }
            }}
          >
            {inView ? <source src={getFullUrl(video.url)} /> : null}
            Sorry, your browser doesn't support embedded videos.
          </Video>
        )}

        {userPaused && (
          <VideoOverlay
            onClick={() => {
              setUserPaused(false)
            }}
          />
        )}
      </Background>

      {caption && <Caption>{caption}</Caption>}
    </Wrapper>
  )
}
