import React from "react"
import { useSpring, transform } from "framer-motion"
import useMouseMotion from "./useMouseMotion"
import useDeviceOrientationMotion from "./useDeviceOrientationMotion"

export default function useCardMotionEffect(el, isMouseEnabled, isInView) {
  const [x, y, z] = useMouseMotion(el, isMouseEnabled)

  const rotateX = useSpring(0)
  const rotateY = useSpring(0)
  const translateX = useSpring(0)
  const translateY = useSpring(0)
  const translateZ = useSpring(0)

  React.useEffect(() => {
    function updateTransform() {
      const latestX = x.get()
      const latestY = y.get()
      const latestZ = z.get()

      const positionInput = [-0.5, 0, 0.5]

      const newRotateX = transform(latestY, positionInput, [20, 0, -20])
      rotateX.set(newRotateX)

      const newRotateY = transform(latestX, positionInput, [-30, 0, 30])
      rotateY.set(newRotateY)

      const newTranslateX = transform(latestX, positionInput, [40, 0, -40])
      translateX.set(newTranslateX)

      const newTranslateY = transform(latestY, positionInput, [30, 0, -30])
      translateY.set(newTranslateY)

      const newTranslateZ = transform(latestZ, [0, 1], [0, 60])
      translateZ.set(newTranslateZ)
    }

    const unsubscribeX = x.onChange(updateTransform)
    const unsubscribeY = y.onChange(updateTransform)
    const unsubscribeZ = z.onChange(updateTransform)

    return function cleanup() {
      unsubscribeX()
      unsubscribeY()
      unsubscribeZ()
    }
  }, [x, y, z, rotateX, rotateY, translateX, translateY, translateZ])

  // DeviceOrientation
  const { beta, gamma } = useDeviceOrientationMotion(!isMouseEnabled)

  if (!isMouseEnabled) {
    z.set(isInView ? 1 : 0)
  }

  React.useEffect(() => {
    const betaInput = [0, 20, 60]
    const gammaInput = [-30, 0, 30]

    function updateTransform() {
      const latestBeta = beta.get()
      const latestGamma = gamma.get()

      const newRotateX = transform(latestBeta, betaInput, [-30, 0, 30])
      rotateX.set(newRotateX)

      const newRotateY = transform(latestGamma, gammaInput, [30, 0, -30])
      rotateY.set(newRotateY)

      const newTranslateX = transform(latestGamma, gammaInput, [40, 0, -40])
      translateX.set(newTranslateX)

      const newTranslateY = transform(latestBeta, betaInput, [30, 0, -30])
      translateY.set(newTranslateY)
    }

    let unsubscribeBeta
    let unsubscribeGamma
    if (!isMouseEnabled && isInView) {
      unsubscribeBeta = beta.onChange(updateTransform)
      unsubscribeGamma = gamma.onChange(updateTransform)
      updateTransform()
    }

    return function cleanup() {
      unsubscribeBeta && unsubscribeBeta()
      unsubscribeGamma && unsubscribeGamma()
    }
  }, [
    isMouseEnabled,
    isInView,
    beta,
    gamma,
    rotateX,
    rotateY,
    translateX,
    translateY,
  ])

  return [rotateX, rotateY, translateX, translateY, translateZ]
}
