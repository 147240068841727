import React from "react"
import styled from "styled-components/macro"
import { rem, transparentize } from "polished"
import { color, ease } from "core/theme"
import { Heading } from "elements"

const StyledVideoOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  cursor: pointer;
  display: flex;
  mix-blend-mode: hard-light;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(hsla(0, 0%, 0%, 0.4), transparent),
      ${transparentize(0.8, color.brand.black)};

    opacity: 1;
    transition: opacity 500ms ${ease.inOut};
  }

  > div {
    margin: auto;
    position: relative;
    user-select: none;

    &::before {
      background-color: ${color.brand.red};
      content: "";
      width: 100%;
      height: ${rem(10)};
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1;
      transform: translateY(-50%);
    }

    h2 {
      color: ${color.white};
      margin: 0;
      transition: color 500ms ${ease.inOut};
    }
  }

  &:hover {
    &::before {
      opacity: 0.4;
    }

    > div::before {
      display: none;
    }
  }
`

export default function VideoOverlay({ onClick }) {
  return (
    <StyledVideoOverlay onClick={onClick}>
      <Heading dot="nodot">Play</Heading>
    </StyledVideoOverlay>
  )
}
