// const isDevelopmentEnv = process.env.NODE_ENV !== "production"
const isDevelopmentEnv = false

export const host = isDevelopmentEnv
  ? "http://localhost:1337"
  : "https://strapi.wrong.lv"

export function getFullUrl(url) {
  return host + url
}

const contentUrl = /* process.env.PUBLIC_URL + */ "/content/works"

export function imageWithSrc(projectId, image) {
  if (projectId && image) {
    const imageUrl = `${contentUrl}/${projectId}/min`

    const compoundProps = {}
    if (image.srcSet) {
      compoundProps.srcSet = image.srcSet.reduce(
        (prev, curr) =>
          `${prev ? `${imageUrl + prev},` : ""} ${imageUrl + curr}`
      )
    }

    return {
      ...image,
      ...compoundProps,
      src: imageUrl + image.src,
    }
  }
}
