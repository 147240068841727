import React from "react"
import debounce from "lodash.debounce"

export default function useMatchMedia(minWidth) {
  const [breakpointMatches, setBreakpointMatches] = React.useState(false)

  React.useEffect(() => {
    function onResize() {
      const tabletBreakpoint = window.matchMedia(
        `(min-width: ${minWidth / 16}rem)`
      )
      setBreakpointMatches(tabletBreakpoint.matches)
    }

    const onResizeThrottled = debounce(onResize, 250)
    window.addEventListener("resize", onResizeThrottled)
    onResize()

    return () => {
      window.removeEventListener("resize", onResizeThrottled)
    }
  }, [minWidth])

  return breakpointMatches
}
