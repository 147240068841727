import React from "react"
import styled from "styled-components/macro"
import { motion, useTransform } from "framer-motion"
import { type } from "core/theme"
import { useAnimate } from "core/hooks"

const StyledText = styled.p`
  ${({ lead }) => (lead ? type.leadText : type.bodyText)};
  margin: 0;
  white-space: pre-wrap;
`

const StyledTextContainer = styled.div`
  ${type.bodyText};

  > p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default function Text({ lead = false, as, children, raw, style }) {
  const [ref, animate] = useAnimate({ delay: 0.2 })
  const y = useTransform(animate, [0, 1], [48, 0])
  return (
    <motion.div ref={ref} style={{ y, opacity: animate }}>
      {raw ? (
        <StyledTextContainer dangerouslySetInnerHTML={{ __html: raw() }} />
      ) : (
        <StyledText lead={lead} as={as} style={style}>
          {children}
        </StyledText>
      )}
    </motion.div>
  )
}
