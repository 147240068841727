import React from "react"
import styled, { css } from "styled-components/macro"
import { rem } from "polished"
import { motion, useTransform } from "framer-motion"
import { color, type } from "core/theme"
import { useAnimate } from "core/hooks"

const StyledHeading = styled.h1`
  ${({ as }) => (as === "h1" ? type.primaryHeading : type.secondaryHeading)};

  margin-top: 0;
  margin-bottom: ${rem(20)};
  font-weight: 700;

  &::after {
    content: ".";
    color: ${color.brand.red};
  }

  ::selection {
    color: ${color.brand.black};
    background-color: ${color.brand.red};
  }

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `};

  ${({ dot }) =>
    dot
      ? dot === "nodot"
        ? css`
            &::after {
              display: none;
            }
          `
        : css`
            &::after {
              content: "${dot}";
            }
          `
      : ""};

  del {
    text-decoration-color: ${color.brand.red};
  }

  ins {
    ${type.scribble};

    position: absolute;
    text-decoration: none;
    transform: translate(-50%, -40%) rotate(-5deg);
    white-space: nowrap;
  }
`

export default function Heading({
  primary = false,
  align,
  dot,
  scribble,
  as,
  children,
  style,
}) {
  const [ref, animate] = useAnimate()
  const x = useTransform(animate, [0, 1], [96, 0])

  const clipPath = useTransform(
    animate,
    [0, 1],
    ["inset(0% 80% 0% -15%)", "inset(-15% -15% -15% -15%)"]
  )

  const [, customAfterContent] = /{(.+)}$/.exec(children) || []
  let newChildren = customAfterContent
    ? children.replace(/{(.+)}$/, "")
    : children

  if (scribble) {
    const parts =
      new RegExp(`^(.*)(${scribble.anchor})(.*)$`).exec(children) || []
    const rawText = parts.slice(1).reduce((prev, curr) => {
      const newCurr =
        curr === scribble.anchor
          ? `<nobr><del>${curr}</del> <ins>${scribble.text}</ins></nobr>`
          : curr
      return prev + newCurr
    }, "")

    return (
      <motion.div ref={ref} style={{ clipPath, x, opacity: animate }}>
        <StyledHeading
          as="h1"
          align={align}
          style={style}
          dangerouslySetInnerHTML={{ __html: rawText }}
        />
      </motion.div>
    )
  }

  return (
    <motion.div ref={ref} style={{ clipPath, x, opacity: animate }}>
      <StyledHeading
        as={primary ? "h1" : as || "h2"}
        align={align}
        dot={customAfterContent || dot}
        style={style}
      >
        {newChildren}
      </StyledHeading>
    </motion.div>
  )
}
