import React from "react"
import { useMotionValue } from "framer-motion"

export default function useMouseMotion(el, isMouseEnabled) {
  const x = useMotionValue(0)
  const y = useMotionValue(0)
  const z = useMotionValue(0)

  React.useEffect(() => {
    function handleMouseEnter(event) {
      updateClientRectSize()
      setPositionFromEvent(event)
      z.set(1)
    }

    function handleMouseMove(event) {
      setPositionFromEvent(event)
    }

    function handleMouseLeave() {
      x.set(0)
      y.set(0)
      z.set(0)
    }

    let clientRect
    function updateClientRectSize() {
      const { top, left, width, height } = el.getBoundingClientRect()
      clientRect = {
        width,
        height,
        offsetTop: top + height / 2,
        offsetLeft: left + width / 2,
      }
    }

    function setPositionFromEvent(event) {
      const dx = event.clientX - clientRect.offsetLeft
      const dy = event.clientY - clientRect.offsetTop

      x.set(dx / clientRect.width)
      y.set(dy / clientRect.height)
    }

    if (el && isMouseEnabled) {
      el.addEventListener("mouseenter", handleMouseEnter)
      el.addEventListener("mouseleave", handleMouseLeave)
      el.addEventListener("mousemove", handleMouseMove, { passive: true })
      updateClientRectSize()
    }

    const cleanupEl = el
    return function cleanup() {
      if (cleanupEl) {
        cleanupEl.removeEventListener("mouseenter", handleMouseEnter)
        cleanupEl.removeEventListener("mouseleave", handleMouseLeave)
        cleanupEl.removeEventListener("mousemove", handleMouseMove, {
          passive: true,
        })
      }
    }
  }, [el, isMouseEnabled, x, y, z])

  return [x, y, z]
}
