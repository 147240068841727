import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { color, type } from "core/theme"
import { PageContainer, breakpoint } from "core/layout"

const StyledFooter = styled.footer`
  background-color: ${color.surface.default};
  height: ${rem(54)};

  > div {
    height: 100%;
  }

  ${breakpoint.tablet`
    height: ${rem(74)};
  `};
`

const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`

const ExternalLink = styled.a`
  ${type.bodyText};
  color: ${color.brand.red};
  cursor: pointer;
  display: block;
  padding: 1em;
  text-decoration: none;
  transform: translateX(1em);

  &:hover {
    text-decoration: underline;
  }

  ${breakpoint.tablet`
    & + & {
      margin-left: 2rem;
    }
  `};
`

export default function SocialFooter() {
  return (
    <StyledFooter>
      <PageContainer>
        <Group>
          <ExternalLink
            href="https://www.instagram.com/wrongdigital/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </ExternalLink>
          <ExternalLink
            href="https://www.facebook.com/wrong.lv/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </ExternalLink>
        </Group>
      </PageContainer>
    </StyledFooter>
  )
}