import React from "react"
import { useSpring } from "framer-motion"
import { useInView } from "react-intersection-observer"

const defaultOptions = {
  delay: 0,
  spring: { damping: 50 },
}

export default function useAnimate(options = {}) {
  const compoundOptions = { ...defaultOptions, ...options }

  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const animate = useSpring(0, compoundOptions.spring || {})

  React.useEffect(() => {
    let timeout
    if (inView) {
      if (compoundOptions.delay > 0) {
        timeout = window.setTimeout(() => {
          animate.set(1)
        }, compoundOptions.delay * 1000)
      } else {
        animate.set(1)
      }
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout)
      }
      timeout = null
    }
  }, [compoundOptions.delay, inView, animate])

  return [ref, animate]
}
