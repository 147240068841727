import React from "react"
import { useMotionValue } from "framer-motion"

export default function useDeviceOrientationMotion(isDeviceOrientationEnabled) {
  const alpha = useMotionValue(0)
  const beta = useMotionValue(90)
  const gamma = useMotionValue(0)

  React.useEffect(() => {
    function handleDeviceOrientation(event) {
      alpha.set(event.alpha)
      beta.set(event.beta)
      gamma.set(event.gamma)
    }

    // FIXME: Viens globāls deviceorientation ar pieejamām vērtībām
    // katrai no kartiņām tā, lai neizsauktu papildus re-render.
    if (isDeviceOrientationEnabled) {
      window.addEventListener("deviceorientation", handleDeviceOrientation)
    }

    return () => {
      window.removeEventListener("deviceorientation", handleDeviceOrientation)
    }
  }, [isDeviceOrientationEnabled, alpha, beta, gamma])

  return { alpha, beta, gamma }
}
