import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { motion } from "framer-motion"
import { PageContainer, breakpoint } from "core/layout"

const BarPosition = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 50;
`

const StyledBar = styled(motion.div)`
  background-color: #fff;
  color: #888888;
  font-size: ${rem(15)};
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: ${rem(6)};
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    &:focus {
      outline: none;
    }
  }

  > div {
    width: 100%;
  }

  ${breakpoint.tablet`
    overflow-x: inherit;
  `};

  ${breakpoint.laptop`
    font-size: ${rem(18)};
  `};
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > div:first-child {
    padding: 1em 1.5em;
  }

  ${breakpoint.laptop`
    padding: ${rem(12)} ${rem(36)};
  `};
`

const List = styled.ul`
  color: #000000;
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;

  ${breakpoint.tablet`
    flex-wrap: wrap;
  `};

  ${breakpoint.laptop`
    transform: translateX(1.25em);
  `};
`

const Button = styled.button.attrs({ type: "button" })`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 1em 1.25em;
  white-space: nowrap;

  &:hover {
    color: #ee453b;
  }

  ${({ active }) =>
    active &&
    css`
      color: #ee453b;
      pointer-events: none;
    `}
`

const barVariants = {
  hidden: {
    y: 100,
  },
  visible: {
    y: 0,
    transition: {
      type: "spring",
      damping: 15,
      delay: 2,
    },
  },
}

export default function CategoryFilter({
  categories,
  activeCategory,
  onChange = () => {},
}) {
  return (
    <BarPosition>
      <StyledBar initial="hidden" animate="visible" variants={barVariants}>
        <PageContainer>
          <Wrapper>
            <div>Category</div>

            <div>
              <List>
                <li key="all">
                  <Button
                    active={activeCategory === -1}
                    onClick={() => {
                      onChange(-1)
                    }}
                  >
                    All Projects
                  </Button>
                </li>

                {categories.map(({ id, category }) => {
                  return (
                    <li key={id}>
                      <Button
                        active={activeCategory === id}
                        onClick={() => {
                          onChange(id)
                        }}
                      >
                        {category}
                      </Button>
                    </li>
                  )
                })}
              </List>
            </div>
          </Wrapper>
        </PageContainer>
      </StyledBar>
    </BarPosition>
  )
}
