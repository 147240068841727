import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { color } from "core/theme"
import { breakpoint } from "core/layout"

const StyledAltHeading = styled.h3`
  color: ${color.brand.black};
  font-size: ${rem(22)};
  margin: ${rem(20)} 0 ${rem(10)};

  > span {
    color: ${color.brand.red};
  }

  ${breakpoint.laptop`
    font-size: ${rem(27)};
  `};
`

export default function AltHeading({ children, style }) {
  return <StyledAltHeading style={style}>{children}</StyledAltHeading>
}
