import React from "react"
import styled, { css } from "styled-components/macro"
import { rem } from "polished"
import { motion, useSpring } from "framer-motion"
import { useInView } from "react-intersection-observer"
import { type } from "core/theme"
import { useMatchMedia } from "core/hooks"
import {
  /* AspectRatio, */
  breakpoint,
  halfWidthImgSizes,
  fullWidthImgSizes,
  sizes as breakpointSizes,
} from "core/layout"
import Img from "gatsby-image"

const Figure = styled(motion.figure)`
  margin: 0;
  margin-top: ${rem(20)};

  ${breakpoint.tablet`
    margin-top: ${rem(40)};
  `};

  ${breakpoint.laptop`
    margin-top: ${rem(80)};
  `};
`

const GatsbyImageWrapper = styled(motion.div)`
  ${({ aspectRatio }) =>
    aspectRatio &&
    css`
      .gatsby-image-wrapper > div {
        padding-bottom: ${({ aspectRatio }) => aspectRatio}!important;
      }
    `};
  ${({ objectPosition }) =>
    objectPosition &&
    css`
      .gatsby-image-wrapper img {
        object-position: ${({ objectPosition }) => objectPosition}!important;
      }
    `};
`

const Caption = styled.span`
  ${type.caption};
  display: inline-block;
  margin-top: ${rem(10)};
`

export default function Image(props) {
  const matchTablet = useMatchMedia(breakpointSizes.tablet)
  const opacity = useSpring(0, { damping: 50 })

  const {
    imageDesktop,
    imageMobile,
    sizes,
    alt,
    caption,
    style,
    ratio,
    halfWidth = false,
    pointOfInterest,
    priority,
  } = props

  const sizesAttr = sizes
    ? sizes
    : halfWidth
    ? halfWidthImgSizes
    : fullWidthImgSizes

  const mobileRatio = halfWidth ? 1 : 4 / 3
  const tabletRatio = halfWidth ? 3 / 4 : 9 / 16
  const ratioAttr = matchTablet ? (ratio ? ratio : tabletRatio) : mobileRatio

  const { ref: wrapperRef, inView } = useInView({
    triggerOnce: true,
  })

  function onImageLoad() {
    opacity.set(1)
  }

  let imageSize = imageMobile ? imageMobile : imageDesktop ? imageDesktop : null
  if (matchTablet && imageDesktop) {
    imageSize = imageDesktop
  }

  const image = imageSize.imageFile.childImageSharp
  if (image) {
    const figure = (
      <Figure style={style}>
        <GatsbyImageWrapper
          ref={wrapperRef}
          key={image.url}
          aspectRatio={`${ratioAttr * 100}%`}
          objectPosition={pointOfInterest}
          alt={alt || ""}
          style={{ opacity }}
        >
          {(priority || inView) && image ? (
            <Img
              fluid={{
                ...image.fluid,
                sizes: sizesAttr,
              }}
              onLoad={onImageLoad}
            />
          ) : (
            /* imitē Gatsby image */
            <div className="gatsby-image-wrapper">
              <div />
            </div>
          )}
        </GatsbyImageWrapper>

        {caption && <Caption style={{ opacity }}>{caption}</Caption>}
      </Figure>
    )
    return figure
  }

  return null
}
